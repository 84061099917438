<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Facturas</h1>

    <div class="table-header table-header-border">
      Cantidad de facturas: <b>{{ invoices.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="invoices"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      ref="invoices"
      paginated
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      class="invoices-table"
      scrollable
      :opened-detailed="defaultOpenedDetails"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.CLAVE_FACTURA}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      checkbox-position="left"
      default-sort="CLAVE_FACTURA"
    >
      <template #empty>
        <div class="has-text-centered">No hay facturas</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          v-bind="column"
          :key="column.field"
          :visible="column.display"
          :label="column.label"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>
          <span v-if="column.money && checkIfValid(props.row[column.field])">{{
            props.row[column.field] | money("MXN", 2)
          }}</span>
          <span v-if="!column.money && checkIfValid(props.row[column.field])">
            {{ props.row[column.field] }}</span
          >
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="media-content">
            <div class="content">
              <div class="controls">
                <b-button
                  type="is-primary"
                  @click="openCreditNoteDeliveriesPopup(props.row)"
                  v-if="props.row.STATUS !== 'CA'"
                  >Agregar nota de crédito</b-button
                >

                <b-button
                  type="is-primary"
                  @click="showCreditNoteDetails(props.row)"
                  >Ver notas de crédito</b-button
                >

                <b-button type="is-primary" @click="openAddPorteCard(props.row)"
                  >Agregar carta porte</b-button
                >

                <b-button
                  type="is-primary"
                  @click="showPorteCardsDetails(props.row)"
                  >Ver cartas porte</b-button
                >

                <b-button
                  type="is-primary"
                  @click="downloaInvoice(props.row._id)"
                  >Descargar CFDI</b-button
                >

                <b-button
                  type="is-primary"
                  v-if="props.row.STATUS === 'CA'"
                  @click="downloaCancellationInvoice(props.row._id)"
                  >Descargar cancelación</b-button
                >

                <b-button type="is-primary" @click="openSendCFDI(props.row._id)"
                  >Enviar CFDI y XML</b-button
                >

                <b-button type="is-primary" @click="showXML(props.row._id)"
                  >Ver XML</b-button
                >

                <b-button
                  type="is-primary"
                  v-if="
                    saleInformation.STATUS === 'CA' && props.row.STATUS !== 'CA'
                  "
                  @click="cancelInvoice(props.row._id)"
                  >Cancelar CFDI</b-button
                >

                <b-button
                  type="is-primary"
                  v-if="props.row.STATUS === 'CA'"
                  @click="showAcuseXML(props.row._id)"
                  >Ver acuse de cancelación</b-button
                >
              </div>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <div class="add-global-controls">
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import PorteCardsDetails from "./PorteCardsDetails.vue";
import AddPorteCardModal from "./AddPorteCardModal";
import AddCreditNoteModal from "./AddCreditNoteModal.vue";
import CreditNotesDetails from "./CreditNotesDetails";
import SendCFDIModal from "../../Accounting/components/SendCFDIModal";
import CFDICancellationModal from "../../Accounting/components/CFDICancellationModal.vue";
import XMLViewer from "../../Accounting/components/XMLViewer.vue";
import moment from "moment";
import { EventBus } from "../../../event-bus";

// @ is an alias to /src
export default {
  name: "InvoiceListModal",
  props: ["saleInformation"],
  components: {},
  data() {
    return {
      perPage: 50,
      checkedInvoices: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_FACTURA",
          label: "Clave de la factura",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "creationDateFormatted",
          label: "Fecha",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByCreationDate,
        },
        {
          field: "saleDateFormatted",
          label: "Fecha de venta",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "STATUS_FOR",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CLIENTE_NOMBRE",
          label: "Cliente",
          sortable: true,
          searchable: true,
          display: true,
          money: false,
        },
        {
          field: "ALMACEN_NOMBRE",
          label: "Almacen",
          sortable: true,
          searchable: true,
          display: true,
          money: false,
        },
        {
          field: "SUBTOTAL",
          label: "Sub total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "IVA",
          label: "IVA",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "IEPS",
          label: "IEPS",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "TOTAL",
          label: "Total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
    };
  },
  mounted() {
    moment.locale("es");

    EventBus.$on("closeParetModal", () => {
      this.closeModal();
    });
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async downloaInvoice(invoiceId) {
      try {
        let response = await this.$store.dispatch("GETINVOICEPDF", {
          invoiceId: invoiceId,
          saleId: this.saleInformation._id,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar la factura`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        const linkSource = `data:application/pdf;base64,${response.pdfData}`;
        const downloadLink = document.createElement("a");
        const fileName = response.pdfName;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar la factura`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async downloaCancellationInvoice(invoiceId) {
      try {
        let response = await this.$store.dispatch("GETCANCELLATIONINVOICEPDF", {
          invoiceId: invoiceId,
          saleId: this.saleInformation._id,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar la factura`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        const linkSource = `data:application/pdf;base64,${response.pdfData}`;
        const downloadLink = document.createElement("a");
        const fileName = response.pdfName;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar la factura`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openSendCFDI(invoiceId) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SendCFDIModal,
        props: {
          invoiceId: invoiceId,
          saleId: this.saleInformation._id,
          action: "SENDCFDI",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async showXML(invoiceId) {
      try {
        let response = await this.$store.dispatch("GETINVOICEXML", {
          invoiceId: invoiceId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el xml de la factura`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el xml de la factura`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async showAcuseXML(invoiceId) {
      try {
        let response = await this.$store.dispatch("GETACUSEXML", {
          invoiceId: invoiceId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el acuse de la factura`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el acuse de la factura`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    cancelInvoice(invoiceId) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: CFDICancellationModal,
        props: {
          saleId: this.saleInformation._id,
          invoiceId: invoiceId,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    sortByCreationDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_VENTA).getTime() || -Infinity) -
          (new Date(a.FECHA_VENTA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_VENTA).getTime() || -Infinity) -
          (new Date(b.FECHA_VENTA).getTime() || -Infinity)
        );
      }
    },
    openAddCreditNote(invoiceInformation) {
      let newSale = this.formatSale(this.saleInformation);
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddCreditNoteModal,
        props: {
          saleInformation: newSale,
          invoiceInformation: invoiceInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    formatSale(sale) {
      let currentItems = [];
      for (let singleArticle of sale.VENTA_DET) {
        currentItems.push({
          _id: singleArticle._id,
          article: singleArticle.CLAVE_ART,
          presentation: singleArticle.PRESENTACION,
          CANTIDAD_PRESENTACION: singleArticle.CANTIDAD_PRESENTACION,
          priceList: singleArticle.LISTA_PRECIOS,
          quantity: singleArticle.CANTIDAD_PE,
          quantitySended: singleArticle.CANTIDAD_EN
            ? singleArticle.CANTIDAD_EN
            : 0,
          quantityMissing:
            singleArticle.CANTIDAD_FE || singleArticle.CANTIDAD_FE === 0
              ? singleArticle.CANTIDAD_FE
              : singleArticle.CANTIDAD_PE,
          quantitySendedOriginal: singleArticle.CANTIDAD_EN
            ? singleArticle.CANTIDAD_EN
            : 0,
          quantityMissingOriginal:
            singleArticle.CANTIDAD_FE || singleArticle.CANTIDAD_FE === 0
              ? singleArticle.CANTIDAD_FE
              : singleArticle.CANTIDAD_PE,
          RENGLON: singleArticle.RENGLON,
          price: singleArticle.COSTO_UNI,
          iva: singleArticle.PORC_IVA,
          ieps: singleArticle.PORC_IEPS,
          IVA_GTO: singleArticle.IVA_GTO,
          IEPS_GTO: singleArticle.IEPS_GTO,
          total: singleArticle.TOTAL,
          costTotal: singleArticle.COST_GTO,
          discountTotal: singleArticle.DESC_GTO,
          costs: singleArticle.COSTOS ? singleArticle.COSTOS : [],
          discounts: singleArticle.DESCS ? singleArticle.DESCS : [],
          selectedOption: "empty",
          EXENTO: singleArticle.EXENTO ? singleArticle.EXENTO : "N",
          COSTO_MIN_VTA: singleArticle.COSTO_MIN_VTA,
          UTILIDAD_UNI: singleArticle.utilityTotal,
          UTILIDAD: singleArticle.utilityTotal,
        });
      }
      let saleInformation = {
        _id: sale._id,
        CLAVE_VENTA: sale.CLAVE_VENTA,
        CLIENTE: sale.CLAVE_CLIENTE._id,
        ALMACEN: sale.CLAVE_ALMACEN._id,
        COSTOS: sale.COSTOS ? sale.COSTOS : [],
        DESCS: sale.DESCS ? sale.DESCS : [],
        OBSERVA: sale.OBSERVA,
        OBSERVA_IMP: sale.OBSERVA_IMP,
        currentItems: currentItems,
        FECHA: sale.FECHA,
        subtotal: sale.SUBTOTAL,
        total: sale.TOTAL,
        discounts: sale.DESC_GTO,
        costs: sale.COST_GTO,
        iva: sale.IVA,
        ieps: sale.IEPS,
        exempt: sale.EXENTO,
        shippingQuantity: sale.FLETE,
        shippingPercentage: sale.PORC_FLETE,
        printTimes: sale.IMPRESO,
        STATUS: sale.STATUS,
        EXENTO: sale.EXENTO,
        CLAVE_USO_SAT: sale.CLAVE_USO_SAT,
        CLAVE_REGIMEN: sale.CLAVE_REGIMEN,
        METODO_P: sale.METODO_P,
        CONDICION_P: sale.CONDICION_P,
        PERI_PAGO: sale.PERI_PAGO,
        FORMA_P: sale.FORMA_P,
        REFERENCIA: sale.REFERENCIA,
        NO_CUENTA: sale.NO_CUENTA,
        NO_CLABE: sale.NO_CLABE,
        NO_TARJETA: sale.NO_TARJETA,
        NO_CHEQUE: sale.NO_CHEQUE,
        STATUS_FAC: sale.STATUS_FAC,
        requireInvoice: sale.requireInvoice,
        requireInvoiceAfterSend: sale.requireInvoiceAfterSend,
        requireParcialInvoiceAfterSend: sale.requireParcialInvoiceAfterSend,
        relateCFDI: sale.relateCFDI,
        divideCFDI: sale.divideCFDI,
        UUID_CFDI: sale.UUID_CFDI,
        TIPO_R: sale.TIPO_R,
        VENDEDOR: sale.VENDEDOR,
        UTILIDAD: sale.UTILIDAD,
        DESBLOQUEO_CLIENTE: sale.DESBLOQUEO_CLIENTE,
        DESBLOQUEO_ALMACEN: sale.DESBLOQUEO_ALMACEN,
        sendMaterial: sale.sendMaterial,
        STATUS_ENV: sale.STATUS_ENV,
        useAdvancedPayment: sale.useAdvancedPayment,
        CLAVE_ANTICIPO: sale.CLAVE_ANTICIPO,
        CANTIDAD_ANTICIPO: sale.CANTIDAD_ANTICIPO,
        FECHA_ENVIO: sale.FECHA_ENVIO,
        clientPickup: sale.clientPickup,
        OC_CLIENTE: sale.OC_CLIENTE,
        USUARIO_CREACION: sale.USUARIO_CREACION,
        USUARIO_CONFIRMACION: sale.USUARIO_CONFIRMACION,
        CLAVE_VEHICULO: sale.CLAVE_VEHICULO,
        ANTICIPO_INFO: sale.ANTICIPO_INFO,
        CODIGO_ENVIO: sale.CODIGO_ENVIO,
        POS: sale.POS,
        RAZON_SOCIAL: sale.RAZON_SOCIAL,
      };
      return saleInformation;
    },
    showCreditNoteDetails(invoiceInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: CreditNotesDetails,
        props: {
          saleInformation: this.saleInformation,
          invoiceInformation: invoiceInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddPorteCard(invoiceInformation) {
      let newSale = this.formatSale(this.saleInformation);
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddPorteCardModal,
        props: {
          saleInformation: newSale,
          invoiceInformation: invoiceInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    showPorteCardsDetails(invoiceInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PorteCardsDetails,
        props: {
          saleInformation: this.saleInformation,
          invoiceInformation: invoiceInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openCreditNoteDeliveriesPopup(invoiceInformation) {
      if (this.saleInformation.RESTA < this.saleInformation.TOTAL) {
        this.$buefy.dialog.confirm({
          title: "Confirmar creación de nota de crédito",
          message: `Esta venta cuenta con uno o más envios de material, ¿Estas seguro que deseas crear una nota de crédito?`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: true,
          onConfirm: () => {
            this.openAddCreditNote(invoiceInformation);
          },
        });
        return;
      }

      this.openAddCreditNote(invoiceInformation);
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    invoices() {
      return this.saleInformation.FACTURAS.map((singleInvoice) => {
        const singleInvoiceWithProps = { ...singleInvoice };

        singleInvoiceWithProps.creationDateFormatted = moment(
          singleInvoice.FECHA
        ).format("DD-MM-YYYY HH:mm");
        singleInvoiceWithProps.saleDateFormatted = moment(
          singleInvoice.FECHA_VENTA
        ).format("DD-MM-YYYY HH:mm");

        singleInvoiceWithProps.CLIENTE_NOMBRE =
          singleInvoice.CLAVE_CLIENTE.NOMBRE_EMPRESA;
        singleInvoiceWithProps.ALMACEN_NOMBRE =
          singleInvoice.CLAVE_CLIENTE.NOMBRE_EMPRESA;

        if (singleInvoiceWithProps.STATUS === "AC") {
          singleInvoiceWithProps.STATUS_FOR = "Activa";
        }

        if (singleInvoiceWithProps.STATUS === "CA") {
          singleInvoiceWithProps.STATUS_FOR = "Cancelada";
        }

        return singleInvoiceWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
