<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Aclaraciones</h1>

    <div class="invoice-div-bottom-div">
      <div class="table-header providers-list-global table-header-border">
        Cantidad de aclaraciones:
        <b>{{ currentObjects.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="currentObjects"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="sales"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de aclaración`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="CLAVE_ACLARACION"
      >
        <template #empty>
          <div class="has-text-centered">No hay aclaraciones</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Folio" field="CLAVE_ACLARACION" sortable>
            <span v-if="!checkIfValid(props.row.CLAVE_ACLARACION)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.CLAVE_ACLARACION }}</span>
          </b-table-column>

          <b-table-column label="Estatus" field="STATUS_FOR" sortable>
            <span>{{ props.row.STATUS_FOR }}</span>
          </b-table-column>

          <b-table-column label="Fecha" field="formatedDate" sortable>
            <span v-if="!checkIfValid(props.row.formatedDate)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.formatedDate)">{{
              props.row.formatedDate
            }}</span>
          </b-table-column>

          <b-table-column label="Tipo" field="TIPO" sortable>
            <span v-if="!checkIfValid(props.row.TIPO)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.TIPO)">{{
              props.row.TIPO === "D" ? "Devolución" : "Importe"
            }}</span>
          </b-table-column>

          <b-table-column
            label="Concepto de descuento"
            field="CVE_CONCP.CONCEPTO"
            sortable
          >
            <span v-if="!checkIfValid(props.row.CVE_CONCP)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.CVE_CONCP)">{{
              props.row.CVE_CONCP.CONCEPTO
            }}</span>
          </b-table-column>

          <b-table-column label="Sub total" field="SUB_TOTAL" sortable>
            <span v-if="!checkIfValid(props.row.SUB_TOTAL)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.SUB_TOTAL)">{{
              props.row.SUB_TOTAL | money("MXN", 2)
            }}</span>
          </b-table-column>

          <b-table-column label="IVA" field="IVA_GTO" sortable>
            <span v-if="!checkIfValid(props.row.IVA_GTO)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.IVA_GTO)">{{
              props.row.IVA_GTO | money("MXN", 2)
            }}</span>
          </b-table-column>

          <b-table-column label="IEPS" field="IEPS_GTO" sortable>
            <span v-if="!checkIfValid(props.row.IEPS_GTO)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.IEPS_GTO)">{{
              props.row.IEPS_GTO | money("MXN", 2)
            }}</span>
          </b-table-column>

          <b-table-column label="Total" field="TOTAL_GTO" sortable>
            <span v-if="!checkIfValid(props.row.TOTAL_GTO)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.TOTAL_GTO)">{{
              props.row.TOTAL_GTO | money("MXN", 2)
            }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="showClarification(props.row)"
                    >Ver aclaración</b-button
                  >

                  <b-button
                    v-if="props.row.STATUS === 'AC'"
                    type="is-primary"
                    @click="
                      startCancelEvent(
                        'danger',
                        function () {
                          cancelClarification(props.row);
                        },
                        'cancelar clarificación'
                      )
                    "
                    >Cancelar aclaración</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddClarificationModal from "./AddClarificationModal";
import { openConfirmationModalDanger } from "../../../utils/component-fns";
import moment from "moment";
export default {
  name: "ClarificationsDetails",
  props: ["saleInformation"],
  components: {},
  data() {
    return {
      currentMovements: [],
      perPage: 25,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      TOTAL_PAGADO: 0,
    };
  },
  mounted() {
    moment.locale("es");
    this.currentObjec;
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    showClarification(clarificationInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddClarificationModal,
        props: {
          saleInformation: this.saleInformation,
          objectInformation: clarificationInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async cancelClarification(clarification) {
      try {
        let response = await this.$store.dispatch("CANCELCLARIFICATION", {
          creditNoteId: clarification._id,
          saleId: this.saleInformation._id,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se canceló exitosamente la nota de crédito",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          this.closeModal();
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al cancelar la nota de crédito en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al cancelar la nota de crédito en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    currentObjects() {
      return this.saleInformation.ACLARAC.map((singleClarification) => {
        const singleClarificationWithProps = { ...singleClarification };
        singleClarificationWithProps.formatedDate = moment(
          singleClarification.FECHA
        ).format("DD-MM-YYYY HH:mm");
        if (singleClarification.STATUS === "AC") {
          singleClarificationWithProps.STATUS_FOR = "Activa";
        } else if (singleClarification.STATUS === "CA") {
          singleClarificationWithProps.STATUS_FOR = "Cancelada";
        }
        return singleClarificationWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
