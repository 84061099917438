<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar nota de crédito</h1>

    <div>
      <h3 class="form-division">Información general</h3>

      <b-checkbox
        v-model="newClarification.requireCDFI"
        v-if="!objectInformation"
        :disabled="objectInformation !== undefined"
      >
        Timbrar nota de crédito
      </b-checkbox>

      <div class="invoice-add-item special-margin">
        <b-field
          class="invoice-add-secondary-item-one required-input"
          label-position="inside"
          :label="`Tipo de nota de crédito (Req)`"
          :type="{ 'is-danger': newClarificationErrors.TIPO }"
          :message="{
            'El tipo no es valido': newClarificationErrors.TIPO,
          }"
        >
          <b-select
            :placeholder="`Tipo de nota de crédito`"
            icon="numeric-9-circle-outline"
            required
            type="number"
            step="any"
            v-model="newClarification.TIPO"
            expanded
            @input="handleCreditNoteType()"
            :disabled="objectInformation !== undefined"
          >
            <option value="" disabled>Seleccione una opción</option>
            <option value="I">Importe</option>
            <option value="D">Devolución</option>
            <option value="A">Amortización</option>
          </b-select>
        </b-field>
        <b-field
          v-if="newClarification.TIPO === 'A'"
          class="invoice-add-secondary-item-one required-input"
          label="Anticipo (Opc)"
          expanded
          label-position="inside"
        >
          <b-select
            class="required-input"
            placeholder="Ejemplo: 100"
            expanded
            icon="credit-card-outline"
            v-model="newClarification.CLAVE_ANTICIPO"
            :disabled="objectInformation"
          >
            <option :value="undefined" disabled>Sin seleccionar</option>
            <option
              :value="singleAdvancesPayment._id"
              v-for="singleAdvancesPayment in advancedPayment"
              v-bind:key="singleAdvancesPayment._id"
            >
              {{ singleAdvancesPayment.NUM_FOLIO }}{{ "  "
              }}{{ singleAdvancesPayment.PAGO_PARC | money("MXN", 2) }}
            </option>
          </b-select>
        </b-field>
        <b-field
          v-if="newClarification.TIPO === 'A'"
          class="invoice-add-secondary-item-one"
          label-position="inside"
          label="Saldo restante del anticipo a usar (Opc)"
        >
          <MoneyInput
            currency="MXN"
            :digits="4"
            placeholder="Folio"
            icon="cash"
            :value="getRemainingBalanceAdvancedPayment()"
            disabled
            required
          >
          </MoneyInput>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          label="Fecha (Req)"
          :type="{ 'is-danger': newClarificationErrors.FECHA }"
          :message="{
            'La fecha no es valida': newClarificationErrors.FECHA,
          }"
        >
          <b-datepicker
            :show-week-number="true"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            placeholder="Fecha"
            icon="calendar-today"
            required
            v-model="newClarification.FECHA"
            trap-focus
            :disabled="objectInformation !== undefined"
          >
          </b-datepicker>
        </b-field>

        <b-field
          class="required-input invoice-add-secondary-item-three"
          label-position="inside"
          label="Descripción (Req)"
          :type="{ 'is-danger': newClarificationErrors.DESCRIPCION }"
          :message="{
            'La descripción no es valida': newClarificationErrors.DESCRIPCION,
          }"
          requried
        >
          <b-input
            placeholder="DESCRIPCION"
            type="text"
            icon="magnify-plus-outline"
            v-model="newClarification.DESCRIPCION"
            required
            :disabled="objectInformation !== undefined"
          >
          </b-input>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          label="Uso del CFDI (Req)"
          expanded
          :type="{ 'is-danger': newClarificationErrors.CLAVE_USO_SAT }"
          :message="{
            'El uso del CFDI no es valido':
              newClarificationErrors.CLAVE_USO_SAT,
          }"
        >
          <b-select
            placeholder="Ejemplo: Uso del CFDI"
            icon="domain"
            expanded
            v-model="newClarification.CLAVE_USO_SAT"
            required
            class="required-input"
            :disabled="objectInformation !== undefined"
          >
            <option value="" disabled>Seleccione un uso del CFDI</option>
            <option
              v-for="singleCfdiUse in cfdiUses"
              :value="singleCfdiUse._id"
              :key="singleCfdiUse._id"
            >
              {{ singleCfdiUse.CVE_USO_SAT }} {{ singleCfdiUse.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label="Régimen fiscal (Req)"
          expanded
          label-position="inside"
          :type="{ 'is-danger': newClarificationErrors.CLAVE_REGIMEN }"
          :message="{
            'El uso del CFDI no es valido':
              newClarificationErrors.CLAVE_REGIMEN,
          }"
        >
          <b-select
            placeholder="Ejemplo: Régimen fiscal"
            icon="briefcase-outline"
            expanded
            v-model="newClarification.CLAVE_REGIMEN"
            required
            class="required-input"
            :disabled="objectInformation !== undefined"
          >
            <option value="" disabled>Seleccione un régimen</option>
            <option
              v-for="singleRegimen in regimenes"
              :value="singleRegimen._id"
              :key="singleRegimen._id"
            >
              {{ singleRegimen.CVE_REG_SAT }} {{ singleRegimen.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label="Forma de pago (Req)"
          expanded
          label-position="inside"
          :type="{ 'is-danger': newClarificationErrors.FORMA_P }"
          :message="{
            'La forma de pago no es válida': newClarificationErrors.FORMA_P,
          }"
        >
          <b-select
            placeholder="Ejemplo: Forma de pago"
            icon="credit-card-multiple-outline"
            expanded
            v-model="newClarification.FORMA_P"
            required
            class="required-input"
            :disabled="objectInformation !== undefined"
          >
            <option value="" disabled>Selecciones una forma de pago</option>
            <option
              :value="singlePaymentType._id"
              v-for="singlePaymentType in paymentTypes"
              v-bind:key="singlePaymentType._id"
            >
              {{ singlePaymentType.DESCRIBE_P }}
            </option>
          </b-select>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label="Tipo de relación (Req)"
          expanded
          label-position="inside"
          :type="{ 'is-danger': newClarificationErrors.TIPO_R }"
          :message="{
            'El tipo de relación no es válido': newClarificationErrors.TIPO_R,
          }"
        >
          <b-select
            placeholder="Ejemplo: Tipo de relación"
            icon="relation-many-to-many"
            expanded
            v-model="newClarification.TIPO_R"
            required
            class="required-input"
          >
            <option value="" disabled>Selecciones un tipo de relación</option>
            <option
              :value="singleRelation.CLAVE"
              v-for="singleRelation in creditNoteRelations"
              v-bind:key="singleRelation.CLAVE"
            >
              {{ singleRelation.CLAVE }} {{ singleRelation.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>
      </div>

      <h3 class="form-division">Detalles en nota de crédito</h3>
      <b-table
        pagination-position="both"
        :data="newClarification.items"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="purchaseOrder"
        custom-row-key="RENGLON"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        class="purchase-order-table providers-list-global"
        scrollable
        default-sort="CONCEP_IE"
      >
        <template #empty>
          <div class="has-text-centered">No hay detalles</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Concepto" field="CONCEP_IE" sortable>
            <b-input
              placeholder="Ejemplo: Pago de Luz"
              expanded
              v-model="props.row.CONCEP_IE"
              required
              :disabled="
                newClarification.TIPO === 'D' || objectInformation !== undefined
              "
            ></b-input>
          </b-table-column>

          <b-table-column label="Descripción" field="DESCRIBEAR" sortable>
            <b-input
              placeholder="Ejemplo: Pago de enero"
              expanded
              v-model="props.row.DESCRIBEAR"
              required
              :disabled="
                newClarification.TIPO === 'D' || objectInformation !== undefined
              "
            ></b-input>
          </b-table-column>

          <b-table-column
            label="Presentación"
            field="PRESENTACIONES"
            sortable
            :visible="newClarification.TIPO === 'D'"
          >
            <b-field class="invoice-option-select">
              <b-select
                placeholder="Ejemplo: PIEZA"
                expanded
                v-model="props.row.PRESENTACION"
                disabled
                v-if="
                  newClarification.TIPO === 'D' ||
                  (objectInformation && objectInformation.TIPO === 'D')
                "
              >
                <option value="" disabled>Presentaciones</option>
                <option
                  value="UB"
                  v-if="props.row.article && !props.row.article.UNIDAD_EMP"
                >
                  Unidad de medida base
                </option>
                <option
                  value="UB"
                  v-if="props.row.article && props.row.article.UNIDAD_EMP"
                >
                  {{
                    capitalizeString(props.row.article.UNIDAD_EMP.DESCRIP_MED)
                  }}
                </option>
                <!-- eslint-disable vue/no-use-v-if-with-v-for -->
                <option
                  v-for="singlePresentation in props.row.article.PRESENTACIONES"
                  :value="singlePresentation._id"
                  :key="singlePresentation._id"
                >
                  {{ singlePresentation.NOMBRE }}
                </option>

                <!-- eslint-enable -->
              </b-select>
            </b-field>
          </b-table-column>

          <b-table-column label="Costo" field="COSTO_UNI" sortable>
            <b-input
              placeholder="Ejemplo: 100"
              type="Number"
              step="any"
              expanded
              v-model="props.row.COSTO_UNI"
              required
              @input.native="updateTotal(props.row)"
              :disabled="
                newClarification.TIPO === 'D' || objectInformation !== undefined
              "
            ></b-input>
          </b-table-column>

          <b-table-column
            label="Cantidad vendida"
            field="CANTIDAD_VENDIDA"
            sortable
            :visible="newClarification.TIPO === 'D' && !objectInformation"
          >
            <b-input
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              :value="props.row.CANTIDAD_VENDIDA"
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column label="Cantidad" field="CANTIDAD" sortable>
            <b-input
              :disabled="
                newClarification.TIPO !== 'D' || objectInformation !== undefined
              "
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              v-model="props.row.CANTIDAD"
              @input.native="updateTotal(props.row)"
            ></b-input>
          </b-table-column>

          <b-table-column label="Porcentaje de IVA" field="PORC_IVA" sortable>
            <b-input
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              v-model="props.row.PORC_IVA"
              @input.native="updateTotal(props.row)"
              :disabled="
                newClarification.TIPO === 'D' || objectInformation !== undefined
              "
            />
          </b-table-column>

          <b-table-column label="Porcentaje de IEPS" field="PORC_IEPS" sortable>
            <b-input
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              v-model="props.row.PORC_IEPS"
              @input.native="updateTotal(props.row)"
              :disabled="
                newClarification.TIPO === 'D' || objectInformation !== undefined
              "
            />
          </b-table-column>

          <b-table-column label="Total" field="TOTAL" sortable>
            <b-input
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              v-model="props.row.TOTAL"
              required
              :disabled="
                newClarification.TIPO === 'D' || objectInformation !== undefined
              "
              @input.native="calculatePricesCreditNote(props.row)"
            ></b-input>
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div class="invoice-payment-total-info-discounts">
      <div class="invoice-payment-total-left"></div>
      <div class="invoice-payment-total-rigth">
        <strong>Subtotal:</strong>
        <strong class="invoice-payment-total-money">{{
          this.newClarification.SUB_TOTAL | money("MXN", 2)
        }}</strong>
      </div>
    </div>

    <div class="invoice-payment-total-info-discounts">
      <div class="invoice-payment-total-left"></div>
      <div class="invoice-payment-total-rigth">
        <strong>IVA:</strong>
        <strong class="invoice-payment-total-money">{{
          this.newClarification.IVA_GTO | money("MXN", 2)
        }}</strong>
      </div>
    </div>

    <div class="invoice-payment-total-info-discounts">
      <div class="invoice-payment-total-left"></div>
      <div class="invoice-payment-total-rigth">
        <strong>IEPS:</strong>
        <strong class="invoice-payment-total-money">{{
          this.newClarification.IEPS_GTO | money("MXN", 2)
        }}</strong>
      </div>
    </div>

    <div class="invoice-payment-total-info">
      <div class="invoice-payment-total-left"></div>
      <div class="invoice-payment-total-rigth">
        <strong>Total:</strong>
        <strong class="invoice-payment-total-money">{{
          this.newClarification.TOTAL_GTO | money("MXN", 2)
        }}</strong>
      </div>
    </div>

    <div class="add-global-controls">
      <b-button
        type="is-success"
        @click="saveCreditNote()"
        v-if="!objectInformation"
        >Agregar nota de crédito</b-button
      >

      <b-button
        type="is-success"
        v-if="objectInformation && objectInformation.STATUS_FAC === 'SF'"
        @click="saveCreditNoteInvoice()"
        >Timbrar nota de crédito</b-button
      >

      <b-button
        type="is-danger"
        @click="closeModal()"
        :class="{
          'cancel-button':
            !objectInformation ||
            (objectInformation && objectInformation.STATUS_FAC === 'SF'),
        }"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CreditNoteRelations from "../../../data/credit-note-relations";
import { capitalizeFirstLetter } from "../../../utils/fns";
import moment from "moment";
import {
  checkValidationErrors,
  toFixedNoRound,
  round,
  validateFields,
} from "../../../utils/fns";
import { fillObjectForm } from "../../../utils/component-fns";
import { EventBus } from "../../../event-bus.js";
import { cfdiUseDevoluciones } from "../../../utils/env-variables-fns";

export default {
  name: "AddCreditNoteModal",
  props: ["saleInformation", "objectInformation", "invoiceInformation"],
  components: {},
  data() {
    return {
      // Table values
      newClarification: {
        FECHA: new Date(),
        DESCRIPCION: "",
        TIPO: "I",
        SUB_TOTAL: 0,
        IVA_GTO: 0,
        IEPS_GTO: 0,
        IMPIMPTO1: 0,
        TOTAL_GTO: 0,
        STATUS: "AC",
        VENTA: this.saleInformation._id,
        FACTURA: this.invoiceInformation._id,
        EXENTO: 0,
        items: [
          {
            CONCEP_IE: "",
            RENGLON: 1,
            RENGLON_VENTA: -1,
            PORC_IVA: 16,
            PORC_IEPS: 0,
            COSTO_UNI: 0,
            IVA_GTO: 0,
            IEPS_GTO: 0,
            DESCRIBEAR: "",
            SUB_TOT: 0,
            TOTAL: 0,
            CANTIDAD: 1,
          },
        ],
        requireCDFI: true,
        CLAVE_USO_SAT: "",
        CLAVE_REGIMEN: "",
        FORMA_P: "",
        TIPO_R: "",
        cfdiUseDevoluciones: cfdiUseDevoluciones,
        CLAVE_ANTICIPO: undefined,
      },
      newClarificationErrors: {
        DESCRIPCION: false,
        TIPO: false,
        FECHA: false,
        CLAVE_USO_SAT: false,
        CLAVE_REGIMEN: false,
        FORMA_P: false,
        TIPO_R: false,
      },
      moment: moment,
      perPage: 25,
      checkedWarehouses: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      creditNoteRelations: CreditNoteRelations,
      cfdiUseDevoluciones: cfdiUseDevoluciones,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    // Fill the brand information in form fields
    if (this.objectInformation) {
      // Fill the form with received information
      fillObjectForm(this.objectInformation, this.newClarification);

      if (this.objectInformation.NOTAC_DET) {
        this.newClarification.items = this.objectInformation.NOTAC_DET;
      }
      if (this.objectInformation.FECHA) {
        this.newClarification.FECHA = new Date(this.objectInformation.FECHA);
      }
      if (this.objectInformation.IVA_GTO) {
        this.newClarification.IVA_GTO = this.objectInformation.IVA_GTO;
      }

      if (this.objectInformation.IEPS_GTO) {
        this.newClarification.IEPS_GTO = this.objectInformation.IEPS_GTO;
      }

      for (const singleItem of this.newClarification.items) {
        singleItem.article = singleItem.CLAVE_ART;
      }
    }

    if (!this.objectInformation) {
      this.newClarification.CLAVE_USO_SAT = cfdiUseDevoluciones;
      this.newClarification.TIPO_R = "01";
    }

    if (this.saleInformation && !this.objectInformation) {
      this.newClarification.CLAVE_REGIMEN = this.saleInformation.CLAVE_REGIMEN;
      this.newClarification.FORMA_P = this.saleInformation.FORMA_P;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    closeParentModal() {
      EventBus.$emit("closeParetModal");
      this.closeModal();
    },
    capitalizeString(string) {
      return capitalizeFirstLetter(string);
    },
    async saveCreditNote() {
      try {
        this.articleRequiredErrors = validateFields(
          this.newClarification,
          this.newClarificationErrors
        );
        if (checkValidationErrors(this.newClarificationErrors)) {
          if (!this.validateCreditNoteTotal()) {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la nota de crédito el total de la nota de crédito no puede ser mayor al total de la factura",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            return;
          }
          if (!this.checkAdvancedPaymentBalance()) {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la nota de crédito el total de la nota de crédito no puede ser mayor al saldo restante del anticipo a usar",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            return;
          }
          let response = await this.$store.dispatch("SAVECREDITNOTE", {
            clarification: {
              ...this.newClarification,
              items: this.checkInformation(this.newClarification),
            },
            saleId: this.saleInformation._id,
          });
          if (response === "Success") {
            this.closeParentModal();
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la nota de crédito",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {},
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la nota de crédito en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la nota de crédito en la base de datos revise los campos requeridos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardarla nota de crédito en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    getIva(item) {
      if (item.article) {
        if (
          item.article.CANT_IVA !== undefined &&
          item.article.CANT_IVA !== null
        ) {
          return item.article.CANT_IVA;
        } else {
          return 16;
        }
      }
      return 0;
    },
    getIeps(item) {
      if (
        item.article.CANT_IEPS !== undefined &&
        item.article.CANT_IEPS !== null
      ) {
        return item.article.CANT_IEPS;
      }
      return 0;
    },
    updateTotal(expenseInformation, changeTotal = true) {
      if (
        this.clarificationTypeValue === "I" ||
        this.clarificationTypeValue === "A"
      ) {
        let ivaTotal = toFixedNoRound(
          expenseInformation.COSTO_UNI *
            expenseInformation.CANTIDAD *
            (expenseInformation.PORC_IVA / 100),
          3
        );
        let iepsTotal = toFixedNoRound(
          expenseInformation.COSTO_UNI *
            expenseInformation.CANTIDAD *
            (expenseInformation.PORC_IEPS / 100),
          3
        );

        let total =
          Number(expenseInformation.COSTO_UNI) * expenseInformation.CANTIDAD +
          Number(ivaTotal) +
          Number(iepsTotal);

        expenseInformation.SUB_TOT = toFixedNoRound(
          expenseInformation.COSTO_UNI * expenseInformation.CANTIDAD,
          3
        );
        expenseInformation.IVA_GTO = toFixedNoRound(ivaTotal, 3);
        expenseInformation.IEPS_GTO = toFixedNoRound(iepsTotal, 3);
        if (changeTotal) {
          expenseInformation.TOTAL = toFixedNoRound(total, 3);
        }
        this.newClarification.SUB_TOTAL = round(
          expenseInformation.COSTO_UNI * expenseInformation.CANTIDAD,
          2
        );
        this.newClarification.IVA_GTO = toFixedNoRound(ivaTotal, 3);
        this.newClarification.IEPS_GTO = toFixedNoRound(iepsTotal, 3);
        this.newClarification.IMPIMPTO1 = toFixedNoRound(
          expenseInformation.IVA_GTO + expenseInformation.IEPS_GTO,
          3
        );
        this.newClarification.TOTAL_GTO = toFixedNoRound(total, 3);
        this.newClarification.EXENTO = 0;
      } else if (this.clarificationTypeValue === "D") {
        let total = 0;
        let subtotal = 0;
        let iva = 0;
        let ieps = 0;
        let excent = 0;
        for (const [
          singleItemIndex,
          singleItem,
        ] of this.newClarification.items.entries()) {
          if (singleItem.article) {
            let ivaTotal = 0;
            let iepsTotal = 0;
            let priceQuantity = toFixedNoRound(
              singleItem.COSTO_UNI * singleItem.CANTIDAD,
              2
            );

            let totalItem = 0;
            totalItem += priceQuantity;
            subtotal += priceQuantity;

            // Calculate iva quantity
            let itemIva = toFixedNoRound(
              priceQuantity * (singleItem.PORC_IVA / 100),
              3
            );
            totalItem += itemIva;
            ivaTotal += itemIva;
            iva += itemIva;

            // Calculate ieps quantity
            let itemIeps = toFixedNoRound(
              priceQuantity * (singleItem.PORC_IEPS / 100),
              3
            );
            totalItem += itemIeps;
            iepsTotal += itemIeps;
            ieps += itemIeps;

            // Add iva excent to excent total
            if (singleItem.article && singleItem.article.TIPO_IVA === "EX") {
              excent += priceQuantity;
            }

            // set items values to the ones needed
            this.newClarification.items[singleItemIndex].SUB_TOT =
              toFixedNoRound(priceQuantity, 3);
            this.newClarification.items[singleItemIndex].IVA_GTO =
              toFixedNoRound(ivaTotal, 3);
            this.newClarification.items[singleItemIndex].IEPS_GTO =
              toFixedNoRound(iepsTotal, 3);
            this.newClarification.items[singleItemIndex].TOTAL = toFixedNoRound(
              totalItem,
              3
            );
          }
        }

        // Update total with iva and ieps
        total += subtotal + iva + ieps;

        // Set aclarations values to the ones needed
        this.newClarification.SUB_TOTAL = toFixedNoRound(subtotal, 3);
        this.newClarification.IVA_GTO = toFixedNoRound(iva, 3);
        this.newClarification.IEPS_GTO = toFixedNoRound(ieps, 3);
        this.newClarification.IMPIMPTO1 = toFixedNoRound(iva + ieps, 3);
        this.newClarification.TOTAL_GTO = toFixedNoRound(total, 3);
        this.newClarification.EXENTO = toFixedNoRound(excent, 3);
      }
    },
    /**
     *
     * @desc checks all items in the aclaration and return only the ones with more than 1 in quantity
     */
    checkInformation(newClarification) {
      const filteredItems = [...newClarification.items]
        .map((singleItem) => {
          const singleItemWithProps = { ...singleItem };
          if (singleItem.article) {
            singleItemWithProps.CLAVE_ART = singleItem.article._id;
          }
          return singleItemWithProps;
        })
        .filter((singleItem) => {
          return (
            singleItem.CONCEP_IE &&
            singleItem.COSTO_UNI &&
            singleItem.TOTAL &&
            Number(singleItem.CANTIDAD) > 0
          );
        });
      return filteredItems;
    },
    /**
     *
     * @desc handles the change of credit note type
     */
    handleCreditNoteType() {
      if (this.newClarification.TIPO === "D") {
        this.newClarification.TIPO_R = "03";
      } else if (this.newClarification.TIPO === "I") {
        this.newClarification.TIPO_R = "01";
      } else if (this.newClarification.TIPO === "A") {
        this.newClarification.TIPO_R = "07";
      }
    },
    async saveCreditNoteInvoice() {
      try {
        let response = await this.$store.dispatch("SAVEINVOICECREDITNOTE", {
          saleId: this.saleInformation._id,
          creditNoteId: this.objectInformation._id,
        });
        if (response === "Success") {
          this.closeParentModal();
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: `Se generó el timbrado exitosamente`,
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al timbrar:  ${
              response ? JSON.stringify(response) : "Error en el servidor"
            }`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al timbrar en la base de datos: ${
            error ? JSON.stringify(error) : "Error en el servidor"
          }`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    validateCreditNoteTotal() {
      return (
        this.newClarification.TOTAL_GTO <=
        Number(this.saleInformation.total) + 0.1
      );
    },
    calculatePricesCreditNote(rowValues) {
      if (this.clarificationTypeValue !== "D") {
        for (const [
          singleItemIndex,
          singleItem,
        ] of this.newClarification.items.entries()) {
          if (singleItem.RENGLON === rowValues.RENGLON) {
            this.newClarification.items[singleItemIndex].COSTO_UNI =
              toFixedNoRound(
                rowValues.TOTAL / (1 + rowValues.PORC_IVA / 100),
                5
              );
          }
        }
        this.updateTotal(rowValues, false);
      }
    },
    getRemainingBalanceAdvancedPayment() {
      if (!this.newClarification.CLAVE_ANTICIPO) {
        return 0;
      }
      for (const singleAdvancesPayment of this.advancedPayment) {
        if (
          singleAdvancesPayment._id === this.newClarification.CLAVE_ANTICIPO
        ) {
          return singleAdvancesPayment.RESTA;
        }
      }
      return 0;
    },
    checkAdvancedPaymentBalance() {
      if (this.newClarification.TIPO !== "A") {
        return true;
      }

      if (!this.newClarification.CLAVE_ANTICIPO) {
        return false;
      }

      for (const singleAdvancesPayment of this.advancedPayment) {
        if (
          singleAdvancesPayment._id === this.newClarification.CLAVE_ANTICIPO
        ) {
          return singleAdvancesPayment.RESTA >= this.newClarification.TOTAL_GTO;
        }
      }

      return false;
    },
  },
  computed: {
    lastClarificationNumber() {
      return this.$store.getters.LASTCLARIFICATIONNUMBER;
    },
    lastCreditNoteNumber() {
      return this.$store.getters.LASTCREDITNOTENUMBER;
    },
    clarificationTypeValue() {
      return this.newClarification.TIPO;
    },
    cfdiUses() {
      return this.$store.getters.SATCFDIUSES.map((singleUse) => {
        const useWithProps = { ...singleUse };
        if (useWithProps.TIPO_P === "A") {
          useWithProps.typeFormatted = "Ambos";
        } else if (useWithProps.TIPO_P === "F") {
          useWithProps.typeFormatted = "Persona física";
        } else if (useWithProps.TIPO_P === "M") {
          useWithProps.typeFormatted = "Persona moral";
        }
        return useWithProps;
      });
    },
    regimenes() {
      return this.$store.getters.SATREGIMENES.map((singleRegimen) => {
        const regimenWithProps = { ...singleRegimen };
        if (regimenWithProps.TIPO_R === "A") {
          regimenWithProps.typeFormatted = "Ambos";
        } else if (regimenWithProps.TIPO_R === "F") {
          regimenWithProps.typeFormatted = "Persona física";
        } else if (regimenWithProps.TIPO_R === "M") {
          regimenWithProps.typeFormatted = "Persona moral";
        }
        return regimenWithProps;
      });
    },
    paymentTypes() {
      return this.$store.getters.PAYMENTTYPES;
    },
    advancedPayment() {
      return this.$store.getters.ADVANCEDPAYMENTS.map((singlePayment) => {
        const paymentWithProps = { ...singlePayment };
        return paymentWithProps;
      }).filter((singlePayment) => {
        return (
          singlePayment.CLAVE_CLIENTE &&
          singlePayment.CLAVE_CLIENTE._id === this.saleInformation.CLIENTE &&
          singlePayment.STATUS_FAC === "TI" &&
          (singlePayment.CLAVE_MOVIMIENTO ||
            singlePayment.OBSERVACIONES.includes("TAN")) &&
          singlePayment.RESTA > 0
        );
      });
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    lastClarificationNumber() {
      this.newClarification.CLAVE_ACLARACION = this.lastClarificationNumber;
    },
    clarificationTypeValue() {
      if (this.clarificationTypeValue === "I" && !this.objectInformation) {
        this.newClarification.SUB_TOTAL = 0;
        this.newClarification.IVA_GTO = 0;
        this.newClarification.IEPS_GTO = 0;
        this.newClarification.IMPIMPTO1 = 0;
        this.newClarification.TOTAL_GTO = 0;
        this.newClarification.items = [
          {
            CONCEP_IE: "",
            RENGLON: 1,
            RENGLON_VENTA: -1,
            PORC_IVA: 16,
            PORC_IEPS: 0,
            COSTO_UNI: 0,
            IVA_GTO: 0,
            IEPS_GTO: 0,
            DESCRIBEAR: "",
            SUB_TOT: 0,
            TOTAL: 0,
            CANTIDAD: 1,
            EXENTO: "N",
          },
        ];
      } else if (
        this.clarificationTypeValue === "A" &&
        !this.objectInformation
      ) {
        let total = this.saleInformation.CANTIDAD_ANTICIPO;
        const ivaPercentage =
          this.saleInformation.ANTICIPO_INFO &&
          this.saleInformation.ANTICIPO_INFO.POR_IVA !== undefined &&
          this.saleInformation.ANTICIPO_INFO.POR_IVA !== null
            ? this.saleInformation.ANTICIPO_INFO.POR_IVA
            : 16;

        let subtotal = toFixedNoRound(total / (1 + ivaPercentage / 100), 3);
        let iva = round(subtotal * (ivaPercentage / 100), 2);

        this.newClarification.SUB_TOTAL = subtotal;
        this.newClarification.IVA_GTO = iva;
        this.newClarification.IEPS_GTO = 0;
        this.newClarification.IMPIMPTO1 = 0;
        this.newClarification.TOTAL_GTO = total;
        this.newClarification.items = [
          {
            CONCEP_IE: "Aplicación de anticipo",
            RENGLON: 1,
            RENGLON_VENTA: -1,
            PORC_IVA: ivaPercentage,
            PORC_IEPS: 0,
            COSTO_UNI: subtotal,
            IVA_GTO: iva,
            IEPS_GTO: 0,
            DESCRIBEAR: "Aplicación de anticipo",
            SUB_TOT: subtotal,
            TOTAL: total,
            CANTIDAD: 1,
            EXENTO: "N",
          },
        ];
      } else if (
        this.clarificationTypeValue === "D" &&
        !this.objectInformation
      ) {
        const saleItems = [];
        for (const [
          singleItemIndex,
          singleItem,
        ] of this.saleInformation.currentItems.entries()) {
          saleItems.push({
            CONCEP_IE: singleItem.article ? singleItem.article.CLAVE_ART : "",
            RENGLON: singleItemIndex + 1,
            RENGLON_VENTA: singleItem.RENGLON,
            PORC_IVA: this.getIva(singleItem),
            PORC_IEPS: this.getIeps(singleItem),
            COSTO_UNI: toFixedNoRound(singleItem.price, 3),
            IVA_GTO: 0,
            IEPS_GTO: 0,
            DESCRIBEAR: singleItem.article
              ? singleItem.article.DESCRIPCION
              : "",
            SUB_TOT: 0,
            TOTAL: 0,
            CANTIDAD_VENDIDA: singleItem.quantity,
            CANTIDAD: singleItem.quantitySended,
            article: singleItem.article,
            CLAVE_DETALLE: singleItem._id,
            PRESENTACION: singleItem.presentation,
            CANTIDAD_PRESENTACION: singleItem.CANTIDAD_PRESENTACION,
            EXENTO: singleItem.EXENTO,
          });
        }
        this.newClarification.items = saleItems;
        this.updateTotal();
      }
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
