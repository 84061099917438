<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!discountConceptInformation">
      Agregar concepto de descuento
    </h1>

    <div v-if="!discountConceptInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Clave del concepto de descuento (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': discountConceptRequiredErrors.CVE_CONCP }"
        :message="{
          'La clave de concepto del descuento no es valida':
            discountConceptRequiredErrors.CVE_CONCP,
        }"
      >
        <b-input
          placeholder="Ejemplo: Clave 1"
          expanded
          v-model="newDiscountConcept.CVE_CONCP"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Nombre del concepto de descuento (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': discountConceptRequiredErrors.CONCEPTO }"
        :message="{
          'El nombre del concepto de descuento no es valido':
            discountConceptRequiredErrors.CONCEPTO,
        }"
      >
        <b-input
          placeholder="Ejemplo: Devolución"
          expanded
          icon="clipboard"
          v-model="newDiscountConcept.CONCEPTO"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Códgo contable (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Clave 1"
          expanded
          v-model="newDiscountConcept.CODIGO_C"
        ></b-input>
      </b-field>
    </b-field>

    <div class="add-global-controls" v-if="!discountConceptInformation">
      <b-button type="is-success" @click="addBrand()"
        >Crear concepto de descuento</b-button
      >

      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
import { fillObjectForm } from "../../../utils/component-fns";
// @ is an alias to /src
export default {
  name: "AddDiscountConceptModal",
  props: ["discountConceptInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newDiscountConcept: {
        CVE_CONCP: "",
        CONCEPTO: "",
        CODIGO_C: "",
      },
      discountConceptRequiredErrors: {
        CVE_CONCP: false,
        CONCEPTO: false,
      },
    };
  },
  mounted() {
    // Fill the brand information in form fields
    if (this.discountConceptInformation) {
      // Fill the form with received information
      fillObjectForm(this.discountConceptInformation, this.newDiscountConcept);
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves a new brand in the database, display messages according to the status
     */
    async addBrand() {
      try {
        this.discountConceptRequiredErrors = validateFields(
          this.newDiscountConcept,
          this.discountConceptRequiredErrors
        );
        if (checkValidationErrors(this.discountConceptRequiredErrors)) {
          let response = await this.$store.dispatch(
            "SAVEDISCOUNTCONCEPT",
            this.newDiscountConcept
          );
          if (response == "Success") {
            this.deleteInformation();
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el concepto de descuento",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error el concepto de descuento en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el concepto de descuento en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el concepto de descuento en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    deleteInformation() {
      this.newDiscountConcept = {
        CVE_CONCP: "",
        CONCEPTO: "",
        CODIGO_C: "",
      };
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
