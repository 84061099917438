<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls"> </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>

      <div class="global-lists-filters-container"></div>
    </div>

    <div class="table-header">
      Cantidad de artículos faltantes de envio:
      <b>{{ salesMissingArticles.length }}</b>
    </div>

    <b-table
      pagination-position="both"
      :data="salesMissingArticles"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      scrollable
      :checked-rows.sync="checkedArticles"
      :current-page="currentPage"
      :selected.sync="selected"
      ref="SalesMissingArticles"
      paginated
      :per-page="perPage"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="desc"
      checkbox-position="left"
      default-sort="FALTANTE_TOT"
      :opened-detailed="defaultOpenedDetails"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.CLAVE_ART}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
    >
      <template #empty>
        <div class="has-text-centered">No hay artículos faltantes de envio</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          v-bind="column"
          :visible="column.display"
          :key="column.field"
          :label="column.label"
          :custom-sort="column.customSort"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>
          <span v-if="column.money && checkIfValid(props.row[column.field])">{{
            props.row[column.field] | money("MXN", 2)
          }}</span>
          <span v-if="!column.money && checkIfValid(props.row[column.field])">{{
            props.row[column.field]
          }}</span>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="media-content">
            <div class="content">
              <div class="controls">
                <div class="global-list-main-container">
                  <b-table
                    pagination-position="both"
                    :data="props.row.VENTAS_FALTANTES"
                    :striped="true"
                    :bordered="true"
                    :hoverable="true"
                    scrollable
                    ref="missingSales"
                    paginated
                    :current-page="currentPageSales"
                    :per-page="perPageSales"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    default-sort-direction="desc"
                    checkbox-position="left"
                    default-sort="CLAVE_VENTA"
                  >
                    <template #empty>
                      <div class="has-text-centered">No hay ventas</div>
                    </template>

                    <template slot-scope="props">
                      <b-table-column
                        v-for="column in saleColumns"
                        searchable
                        sortable
                        v-bind="column"
                        :key="column.field"
                        :visible="column.display"
                        :label="column.label"
                        :custom-sort="column.customSort"
                        :custom-search="column.customSearch"
                      >
                        <span v-if="!checkIfValid(props.row[column.field])">{{
                          "Sin información"
                        }}</span>
                        <span
                          v-if="
                            column.money &&
                            checkIfValid(props.row[column.field])
                          "
                          >{{ props.row[column.field] | money("MXN", 2) }}</span
                        >
                        <span
                          v-if="
                            !column.money &&
                            checkIfValid(props.row[column.field])
                          "
                          >{{ props.row[column.field] }}</span
                        >
                      </b-table-column>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import Masks from "../../../data/cleave-masks";

export default {
  name: "BackOrderSalesList",
  components: {},
  data() {
    //Proveedores
    return {
      perPage: 50,
      perPageSales: 50,
      checkedArticles: [],
      selected: {},
      currentPage: 1,
      currentPageSales: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_ART",
          label: "Clave de articulo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CLIENTE_FOR",
          label: "Cliente",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "lastBuyFormat",
          label: "Fecha de ultima compra",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByBuyDate,
        },
        {
          field: "lastSaleFormat",
          label: "Fecha de ultima venta",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortBySellDate,
        },
        {
          field: "FALTANTE_TOT",
          label: "Cantidad faltante de enviar",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FALTANTE_TOT_CAN",
          label: "Restante de envio neto",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
      saleColumns: [
        {
          field: "CLAVE_VENTA",
          label: "Folio",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "posFormatted",
          label: "Punto de venta",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FACTURA_FOR",
          label: "Facturas",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_CLIENTE",
          label: "Cliente",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_ALM",
          label: "Almacen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_VENDEDOR",
          label: "Vendedor",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_ALMACEN_ORIGEN",
          label: "Origen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedCreationDate",
          label: "Fecha de la venta",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "RESTA_FOR",
          label: "Restante a pagar en cuenta por cobrar",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortyByCXCTotal,
        },
        {
          field: "CUENTA_STATUS_FOR",
          label: "Estatus de cuenta por cobrar",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "SUBTOTAL",
          label: "Subtotal",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "IVA",
          label: "Iva total",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "TOTAL",
          label: "Total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortyByTotal,
        },
        {
          field: "RESTA_ENV_FOR",
          label: "Restante de envio",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
      masks: Masks,
      filterProvider: "",
    };
  },
  mounted() {},
  async created() {
    moment.locale("es");
  },
  methods: {
    formatedDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    sortByBuyDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FE_ULTCMP).getTime() || -Infinity) -
          (new Date(a.FE_ULTCMP).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FE_ULTCMP).getTime() || -Infinity) -
          (new Date(b.FE_ULTCMP).getTime() || -Infinity)
        );
      }
    },
    sortBySellDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FE_ULTVTA).getTime() || -Infinity) -
          (new Date(a.FE_ULTVTA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FE_ULTVTA).getTime() || -Infinity) -
          (new Date(b.FE_ULTVTA).getTime() || -Infinity)
        );
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    salesMissingArticles() {
      let articlesMissing = [];
      for (const singleSale of this.sales) {
        singleSale.TOTAL = String(singleSale.TOTAL);

        singleSale.posFormatted = singleSale.POS === "S" ? "Sí" : "No";

        if (singleSale.CLAVE_ALMACEN) {
          singleSale.NOMBRE_ALM = singleSale.CLAVE_ALMACEN.NOMBRE_ALM;
        }

        if (singleSale.CLAVE_CLIENTE) {
          singleSale.NOMBRE_CLIENTE = singleSale.CLAVE_CLIENTE.NOMBRE_EMPRESA;
        }

        singleSale.formatedCreationDate = moment(singleSale.FECHA).format(
          "DD-MM-YYYY HH:mm"
        );

        singleSale.advancedPaymentFormatted = "";
        if (singleSale.ANTICIPO_INFO) {
          singleSale.advancedPaymentFormatted =
            singleSale.ANTICIPO_INFO.NUM_FOLIO;
        }

        singleSale.advancedPaymentCreditNote = "No";
        if (singleSale.FACTURAS) {
          for (const singleInvoice of singleSale.FACTURAS) {
            if (singleInvoice.NOTAC) {
              for (const singleCreditNote of singleInvoice.NOTAC) {
                if (singleCreditNote.TIPO === "A") {
                  singleSale.advancedPaymentCreditNote = "Si";
                }
              }
            }
          }
        }

        singleSale.FACTURA_FOR = "";
        if (singleSale.FACTURA_FOR) {
          singleSale.FACTURA_FOR = singleSale.FACTURAS.map((singleInvoice) => {
            return singleInvoice.CLAVE_FACTURA;
          }).toString();
        }

        if (singleSale.STATUS === "AC") {
          singleSale.statusFormatted = "Activa";
        } else if (singleSale.STATUS === "CA") {
          singleSale.statusFormatted = "Cancelada";
          singleSale.RESTA = 0;
        } else if (singleSale.STATUS === "AN") {
          singleSale.statusFormatted = "Activa Nota Crédito";
        }

        if (!singleSale.STATUS_FAC || singleSale.STATUS_FAC === "SF") {
          singleSale.statusFacFormatted = "Sin timbrar";
        } else if (singleSale.STATUS_FAC === "TI") {
          singleSale.statusFacFormatted = "Timbrada";
        } else if (singleSale.STATUS_FAC === "CA") {
          singleSale.statusFacFormatted = "Cancelada";
        } else if (singleSale.STATUS_FAC === "FD") {
          singleSale.statusFacFormatted = "En factura global";
        }

        if (!singleSale.STATUS_ENV || singleSale.STATUS_ENV === "P") {
          singleSale.statusDeliveryFormatted = "Pendiente";
        } else if (singleSale.STATUS_ENV === "I") {
          singleSale.statusDeliveryFormatted = "Incompleto";
        } else if (singleSale.STATUS_ENV === "C") {
          singleSale.statusDeliveryFormatted = "Completo";
        }

        if (singleSale.CUENTAS_COB) {
          singleSale.RESTA_FOR = 0;
          for (const singleIncome of singleSale.CUENTAS_COB) {
            singleSale.RESTA_FOR += singleIncome.RESTA;
          }
          singleSale.RESTA_FOR =
            singleSale.RESTA_FOR > 0 ? String(singleSale.RESTA_FOR) : 0;
        }

        singleSale.CUENTA_STATUS_FOR = "Pendiente de pagar";
        if (singleSale.CUENTAS_COB) {
          for (const singleIncome of singleSale.CUENTAS_COB) {
            if (singleIncome.STATUS === "AC")
              singleSale.CUENTA_STATUS_FOR = "Pendiente de pagar";
            if (singleIncome.STATUS === "PE")
              singleSale.CUENTA_STATUS_FOR = "Pendiente de pagar";
            if (singleIncome.STATUS === "PA")
              singleSale.CUENTA_STATUS_FOR = "Totalmente pagada";
            if (singleIncome.STATUS === "CA") {
              singleSale.CUENTA_STATUS_FOR = "Cancelada";
              singleSale.RESTA_FOR = 0;
            }
            if (singleIncome.STATUS === "VE") {
              singleSale.CUENTA_STATUS_FOR = "Vencida";
            }
          }
        }

        let formattedSaleObejct = {
          CLAVE_VENTA: singleSale.CLAVE_VENTA,
          statusFormatted: singleSale.statusFormatted,
          statusFacFormatted: singleSale.statusFacFormatted,
          statusDeliveryFormatted: singleSale.statusDeliveryFormatted,
          posFormatted: singleSale.posFormatted,
          advancedPaymentFormatted: singleSale.advancedPaymentFormatted,
          advancedPaymentCreditNote: singleSale.advancedPaymentCreditNote,
          FACTURA_FOR: singleSale.FACTURA_FOR,
          NOMBRE_CLIENTE: singleSale.NOMBRE_CLIENTE,
          NOMBRE_ALM: singleSale.NOMBRE_ALM,
          NOMBRE_VENDEDOR: singleSale.NOMBRE_VENDEDOR,
          NOMBRE_ALMACEN_ORIGEN: singleSale.NOMBRE_ALMACEN_ORIGEN,
          formatedCreationDate: singleSale.formatedCreationDate,
          RESTA_FOR: singleSale.RESTA_FOR,
          CUENTA_STATUS_FOR: singleSale.CUENTA_STATUS_FOR,
          SUBTOTAL: singleSale.SUBTOTAL,
          IVA: singleSale.IVA,
          TOTAL: singleSale.TOTAL,
          RESTA_ENV_FOR: singleSale.RESTA > 0 ? singleSale.RESTA : 0,
        };

        for (const singleItem of singleSale.VENTA_DET) {
          if (singleItem.CANTIDAD_FE > 0) {
            let articleWithProps = { ...singleItem.CLAVE_ART };

            articleWithProps.CLAVE_CLIENTE = singleSale.CLAVE_CLIENTE._id;

            if (articleWithProps.FE_ULTVTA) {
              articleWithProps.lastSaleFormat = moment(
                articleWithProps.FE_ULTVTA
              ).format("DD-MM-YYYY HH:mm");
            }

            if (articleWithProps.FE_ULTCMP) {
              articleWithProps.lastBuyFormat = moment(
                articleWithProps.FE_ULTCMP
              ).format("DD-MM-YYYY HH:mm");
            }

            articleWithProps.CLIENTE_FOR =
              singleSale.CLAVE_CLIENTE.NOMBRE_EMPRESA;

            let found = false;
            for (const [
              singleArticleIndex,
              singleArticle,
            ] of articlesMissing.entries()) {
              if (
                singleArticle._id === articleWithProps._id &&
                singleArticle.CLAVE_CLIENTE === singleSale.CLAVE_CLIENTE._id
              ) {
                found = true;

                articlesMissing[singleArticleIndex].FALTANTE_TOT +=
                  singleItem.CANTIDAD_FE;
                articlesMissing[singleArticleIndex].FALTANTE_TOT_CAN +=
                  (singleItem.TOTAL / singleItem.CANTIDAD_FE) *
                  singleItem.CANTIDAD_FE;
                articlesMissing[singleArticleIndex].VENTAS_FALTANTES.push(
                  formattedSaleObejct
                );
              }
            }

            if (!found) {
              articleWithProps.FALTANTE_TOT = singleItem.CANTIDAD_FE;
              articleWithProps.FALTANTE_TOT_CAN =
                (singleItem.TOTAL / singleItem.CANTIDAD_FE) *
                singleItem.CANTIDAD_FE;
              articleWithProps.VENTAS_FALTANTES = [formattedSaleObejct];
              articlesMissing.push(articleWithProps);
            }
          }
        }
      }
      return articlesMissing;
    },
    sales() {
      return this.$store.getters.SALES.map((singleSale) => {
        let saleWithProps = { ...singleSale };
        return saleWithProps;
      }).filter((singleSale) => {
        return singleSale.STATUS_ENV !== "C" && singleSale.STATUS !== "CA";
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
