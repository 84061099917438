/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddDiscountConcept()"
          >Agregar concepto de descuento</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de conceptos de descuento: <b>{{ discountConcepts.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="discountConcepts"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Brands"
        :checked-rows.sync="checkedDiscountConcepts"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CVE_CONCP}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="CVE_CONCP"
      >
        <template #empty>
          <div class="has-text-centered">No hay conceptos de descuento</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <AddDiscountConceptModal
                  :discountConceptInformation="props.row"
                />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddDiscountConceptModal from "./AddDiscountConceptModal.vue";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "DiscountConceptsList",
  components: {
    AddDiscountConceptModal,
  },
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      checkedDiscountConcepts: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CVE_CONCP",
          label: "Clave del concepto",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CONCEPTO",
          label: "Concepto",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CODIGO_C",
          label: "Código contable",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      masks: Masks,
    };
  },
  async created() {
    if (this.$store.getters.DISCOUNTCONCEPTS) {
      await this.$store.dispatch("GETDISCOUNTCONCEPTS");
    }
  },
  methods: {
    openAddDiscountConcept() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddDiscountConceptModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    discountConcepts() {
      return this.$store.getters.DISCOUNTCONCEPTS.map(
        (singleDiscountConcepts) => {
          const discountConceptsWithProps = { ...singleDiscountConcepts };
          return discountConceptsWithProps;
        }
      );
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
