import { render, staticRenderFns } from "./CreditNotesDetails.vue?vue&type=template&id=2037f944&scoped=true&"
import script from "./CreditNotesDetails.vue?vue&type=script&lang=js&"
export * from "./CreditNotesDetails.vue?vue&type=script&lang=js&"
import style0 from "./CreditNotesDetails.vue?vue&type=style&index=0&id=2037f944&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2037f944",
  null
  
)

export default component.exports