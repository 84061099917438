<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls"> </b-field>
    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container"></div>
    </div>

    <div class="table-header">
      Cantidad de Ordenes de compra: <b>{{ purchaseOrders.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="purchaseOrders"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      :checked-rows.sync="checkedOrders"
      :current-page="currentPage"
      :selected.sync="selected"
      ref="ProductsTable"
      paginated
      :per-page="perPage"
      :opened-detailed="defaultOpenedDetails"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.CLAVE_PED}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="desc"
      checkbox-position="left"
      default-sort="CLAVE_PED"
    >
      <template #empty>
        <div class="has-text-centered">No hay ordenes de compra</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          v-bind="column"
          :key="column.field"
          :visible="column.display"
          :label="column.label"
          :custom-sort="column.customSort"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>
          <span v-if="column.money">{{
            props.row[column.field] | money("MXN", 2)
          }}</span>
          <span v-if="!column.money">{{ props.row[column.field] }}</span>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="media-content">
            <div class="content">
              <div class="controls">
                <b-button
                  type="is-primary"
                  @click="showPurchaseOrder(props.row)"
                  >Ver orden de compra</b-button
                >
                <b-button
                  type="is-primary"
                  @click="startGenerateReport(props.row)"
                  >Ver, descargar o enviar orden de compra</b-button
                >
              </div>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import ChangePurchaseOrder from "../../Purchases/components/ChangePurchaseOrder";
import PrintPurchaseOrder from "../../Global/components/print-formats/PrintPurchaseOrder";

export default {
  name: "SalesPurchaseOrdersModal",
  props: ["saleInformation"],
  components: {},
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedOrders: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "NOMBRE_ALM",
          label: "Almacén",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_PRV",
          label: "Proveedor",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "EMAIL_GUA",
          label: "Usuario creador",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "EMAIL_ACT",
          label: "Usuario de ultima edición",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "STATUS_FOR",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedCreationDate",
          label: "Fecha del pedido",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
      ],
      filterProvider: "",
    };
  },
  mounted() {},
  methods: {
    formatPurchaseOrder(purchaseOrder) {
      let currentItems = [];
      for (let singleArticle of purchaseOrder.PEDIDO_DET) {
        currentItems.push({
          _id: singleArticle._id,
          article: singleArticle.CLAVE_ART,
          quantity: singleArticle.CANTIDAD_PE,
          quantityReceived: singleArticle.CANTIDAD_RE
            ? singleArticle.CANTIDAD_RE
            : 0,
          quantityMissing:
            singleArticle.CANTIDAD_PR || singleArticle.CANTIDAD_PR === 0
              ? singleArticle.CANTIDAD_PR
              : singleArticle.CANTIDAD_PE,
          quantityReceivedOriginal: singleArticle.CANTIDAD_RE
            ? singleArticle.CANTIDAD_RE
            : 0,
          quantityMissingOriginal:
            singleArticle.CANTIDAD_PR || singleArticle.CANTIDAD_PR === 0
              ? singleArticle.CANTIDAD_PR
              : singleArticle.CANTIDAD_PE,
          newReception: 0,
          RENGLON: singleArticle.RENGLON,
          iva: singleArticle.PORC_IVA,
          ieps: singleArticle.PORC_IEPS,
          price: singleArticle.COSTO_UNI,
          costTotal: singleArticle.COSTO_PAR,
          discountTotal: singleArticle.DESCTO_PAR,
          costs: singleArticle.COSTOS ? singleArticle.COSTOS : [],
          discounts: singleArticle.DESCS ? singleArticle.DESCS : [],
          total: singleArticle.TOTAL,
          selectedOption: "empty",
          EXENTO: singleArticle.EXENTO,
          IVA_GTO: singleArticle.IVA_GTO,
          IEPS_GTO: singleArticle.IEPS_GTO,
          PRECIO_ORIGINAL: singleArticle.PRECIO_ORIGINAL,
          PRESENTACION: singleArticle.PRESENTACION,
          CANTIDAD_PRESENTACION: singleArticle.CANTIDAD_PRESENTACION,
        });
      }
      let newPurchaseOrder = {
        _id: purchaseOrder._id,
        CLAVE_PED: purchaseOrder.CLAVE_PED,
        PRV: purchaseOrder.CLAVE_PRV,
        COSTOS: purchaseOrder.COSTOS ? purchaseOrder.COSTOS : [],
        DESCS: purchaseOrder.DESCS ? purchaseOrder.DESCS : [],
        ALMACEN: purchaseOrder.CLAVE_ALMACEN,
        OBSERVA_PED: purchaseOrder.OBSERVA_PED,
        STATUS_PED: purchaseOrder.STATUS_PED,
        currentItems: currentItems,
        creationDate: purchaseOrder.FECHA_PED,
        subtotal: purchaseOrder.SUBTOTAL_PED,
        total: purchaseOrder.TOTAL_PED,
        discounts: purchaseOrder.DESCS_GTO ? purchaseOrder.DESCS_GTO : 0,
        costs: purchaseOrder.COSTOS_GTO ? purchaseOrder.COSTOS_GTO : 0,
        iva: purchaseOrder.IVA_PED,
        ieps: purchaseOrder.IEPS,
        EXENTO: purchaseOrder.EXENTO,
        shippingQuantity: purchaseOrder.FLETE_PED,
        shippingPercentage: purchaseOrder.PORC_FLETEP,
        printTimes: purchaseOrder.IMPRESO,
        COMPRADOR: purchaseOrder.COMPRADOR,
        RESTA: purchaseOrder.RESTA,
        EMAIL_GUA: purchaseOrder.EMAIL_GUA,
        EMAIL_ACT: purchaseOrder.email,
        NUM_INTERNO: purchaseOrder.NUM_INTERNO,
        NUM_INTERNO_PROV: purchaseOrder.NUM_INTERNO_PROV,
        FECHA_PRE: purchaseOrder.FECHA_PRE,
        VENTA: purchaseOrder.VENTA,
        TIPO_CREACION: purchaseOrder.creationType,
      };
      return newPurchaseOrder;
    },
    showPurchaseOrder(purchaseOrder) {
      let newPurchaseOrder = this.formatPurchaseOrder(purchaseOrder);
      let startingDateFormated = purchaseOrder.FECHA_PED;
      let creationDate = purchaseOrder.FECHA_PED;
      let currentIndex = newPurchaseOrder.currentItems.length + 1;
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ChangePurchaseOrder,
        props: {
          newPurchaseOrderProp: newPurchaseOrder,
          creationDateProp: new Date(creationDate),
          startingDateFormatedProp: startingDateFormated,
          currentIndexProp: currentIndex,
          type: purchaseOrder.STATUS_PED === "P" ? "0" : "1",
          blockEditGeneralValues: true,
          typeName: `Orden de compra #${purchaseOrder.CLAVE_PED}`,
          showReception: false,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    startGenerateReport(purchaseOrder) {
      let newPurchaseOrder = this.formatPurchaseOrder(purchaseOrder);
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PrintPurchaseOrder,
        props: {
          canConfirm: false,
          title: "Orden de compra",
          purchaseOrderInformation: newPurchaseOrder,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_PED).getTime() || -Infinity) -
          (new Date(a.FECHA_PED).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_PED).getTime() || -Infinity) -
          (new Date(b.FECHA_PED).getTime() || -Infinity)
        );
      }
    },
  },
  created() {
    moment.locale("es");
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    purchaseOrders() {
      return this.saleInformation.ORDENES_COMPRA.map((singlePurchaseOrder) => {
        let purchaseOrderWithProps = { ...singlePurchaseOrder };
        purchaseOrderWithProps.NOMBRE_ALM =
          singlePurchaseOrder.CLAVE_ALMACEN.NOMBRE_ALM;
        purchaseOrderWithProps.NOMBRE_PRV =
          singlePurchaseOrder.CLAVE_PRV.NOMBRE_PRV;
        purchaseOrderWithProps.formatedCreationDate = moment(
          singlePurchaseOrder.FECHA_PED
        ).format("DD-MM-YYYY HH:mm");
        if (singlePurchaseOrder.STATUS_PED === "P") {
          purchaseOrderWithProps.STATUS_FOR = "Pendiente";
        } else if (singlePurchaseOrder.STATUS_PED === "C") {
          purchaseOrderWithProps.STATUS_FOR = "Completa";
        } else if (singlePurchaseOrder.STATUS_PED === "I") {
          purchaseOrderWithProps.STATUS_FOR = "Incompleta";
        } else if (singlePurchaseOrder.STATUS_PED === "CA") {
          purchaseOrderWithProps.STATUS_FOR = "Cancelada";
        }
        return purchaseOrderWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
