/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls"> </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="container add-global-modal-main-container">
      <div class="table-header">
        Cantidad de envios de material: <b>{{ materialDeliveries.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="materialDeliveries"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="sales"
        :checked-rows.sync="checkedDeliveried"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        default-sort="CLAVE_VENTA"
      >
        <template #empty>
          <div class="has-text-centered">No hay recepciones de material</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span
              v-if="column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] | money("MXN", 2) }}</span
            >
            <span
              v-if="!column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] }}</span
            >
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div class="container add-global-modal-main-container">
      <div class="table-header-left">
        Peso total: <b>{{ getTotalWeight() | weight }}</b> <br />
        Peso enviado: <b>{{ getPastShippingWeight() | weight }}</b> <br />
        Peso actual en envio: <b>{{ getCurrentShippingWeight() | weight }}</b>
      </div>

      <b-table
        pagination-position="both"
        :data="formattedSaleInformation.currentItems"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="saleItems"
        custom-row-key="RENGLON"
        paginated
        :current-page="formattedSaleInformationCurrentPage"
        :per-page="formattedSaleInformationPerPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        class="formatted-sale-information-table"
        scrollable
        default-sort="article.DESCRIPCION"
      >
        <template slot-scope="props">
          <b-table-column label="Renglon" field="props.index" sortable>
            <span> {{ props.index + 1 }}</span>
          </b-table-column>

          <b-table-column label="Artículo" field="presentationName" sortable>
            <span>{{ props.row.presentationName }}</span>
          </b-table-column>

          <b-table-column
            label="Clave del artículo"
            field="article.CLAVE_ART"
            sortable
          >
            <span v-if="props.row.article">{{
              props.row.article.CLAVE_ART
            }}</span>
          </b-table-column>

          <b-table-column
            label="Ubicación"
            field="article.DESCRIPCION"
            sortable
          >
            <span>{{
              props.row.article.UBICACION
                ? props.row.article.UBICACION
                : "Sin información"
            }}</span>
          </b-table-column>

          <b-table-column
            label="Cantidad total"
            field="quantity"
            sortable
            searchable
          >
            <b-input
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.quantity"
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column
            label="Cantidad faltante total"
            field="quantityMissing"
            sortable
          >
            <b-input
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.quantityMissing"
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column
            label="Cantidad enviada total"
            field="quantitySended"
            sortable
          >
            <b-input
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.quantitySended"
              disabled
            ></b-input>
          </b-table-column>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import SendArticleDetails from "./SendArticleDetails";
import PrintMaterialDelivery from "../../Global/components/print-formats/PrintMaterialDelivery";
import moment from "moment";
import { openConfirmationModalSucces } from "../../../utils/component-fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "SalesMaterialDeliveriesList",
  props: ["saleInformation", "formattedSaleInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      formattedSaleInformationPerPage: 50,
      checkedDeliveried: [],
      selected: {},
      currentPage: 1,
      formattedSaleInformationCurrentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "NUM_VENTA",
          label: "Folio de venta",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FECHA_FOR",
          label: "Fecha",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "STATUS_FAC_FOR",
          label: "Estatus de facturación",
          sortable: true,
          searchable: true,
          display: true,
          money: false,
        },
        {
          field: "STATUS_ENV_FOR",
          label: "Estatus de envio",
          sortable: true,
          searchable: true,
          display: true,
          money: false,
        },
        {
          field: "USUARIO_CREACION",
          label: "Usuario creación",
          sortable: true,
          searchable: true,
          display: true,
          money: false,
        },
      ],
      masks: Masks,
    };
  },
  async created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    getTotalWeight() {
      let totalWeight = 0;

      for (const singleItem of this.formattedSaleInformation.currentItems) {
        if (singleItem.article && singleItem.article.CANT_PESO) {
          totalWeight +=
            Number(singleItem.quantity) * Number(singleItem.article.CANT_PESO);
        }
      }

      return totalWeight;
    },
    getCurrentShippingWeight() {
      let totalWeight = 0;

      for (const singleItem of this.formattedSaleInformation.currentItems) {
        if (singleItem.newReception) {
          if (singleItem.article && singleItem.article.CANT_PESO) {
            totalWeight +=
              Number(singleItem.newReception) *
              Number(singleItem.article.CANT_PESO);
          }
        }
      }

      return totalWeight;
    },
    getPastShippingWeight() {
      let totalWeight = 0;

      for (const singleItem of this.formattedSaleInformation.currentItems) {
        if (singleItem.quantitySended) {
          if (singleItem.article && singleItem.article.CANT_PESO) {
            totalWeight +=
              Number(singleItem.quantitySended) *
              Number(singleItem.article.CANT_PESO);
          }
        }
      }

      return totalWeight;
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    materialDeliveries() {
      return this.saleInformation.ENVIOS_MATERIAL.map((singleReception) => {
        const singleReceptionWithProps = { ...singleReception };
        singleReceptionWithProps.CHOFER_FOR =
          singleReception.driverDelivery === "S" ? "Sí" : "No";

        singleReceptionWithProps.FECHA_FOR = moment(
          singleReception.FECHA
        ).format("DD-MM-YYYY HH:mm");

        if (singleReception.STATUS_ENV === "I") {
          singleReceptionWithProps.STATUS_ENV_FOR = "Incompleto";
        } else if (singleReception.STATUS_ENV === "P") {
          singleReceptionWithProps.STATUS_ENV_FOR = "Pendiente";
        } else if (singleReception.STATUS_ENV === "C") {
          singleReceptionWithProps.STATUS_ENV_FOR = "Completo";
        }

        singleReceptionWithProps.STATUS_FAC_FOR = "";

        if (
          (this.saleInformation.STATUS_FAC === "SF" ||
            this.saleInformation.STATUS_FAC === "PF" ||
            this.saleInformation.STATUS_FAC === "PP") &&
          !singleReception.FACTURA
        ) {
          singleReceptionWithProps.STATUS_FAC_FOR = "Sin timbrar";
        } else if (
          (this.saleInformation.STATUS_FAC === "SF" ||
            this.saleInformation.STATUS_FAC === "PF" ||
            this.saleInformation.STATUS_FAC === "PP") &&
          singleReception.FACTURA
        ) {
          singleReceptionWithProps.STATUS_FAC_FOR = "Timbrada";
        } else if (this.saleInformation.STATUS_FAC === "TI") {
          singleReceptionWithProps.STATUS_FAC_FOR = "Timbrada";
        } else if (this.saleInformation.STATUS_FAC === "CA") {
          singleReceptionWithProps.STATUS_FAC_FOR = "Cancelada";
        } else if (this.saleInformation.STATUS_FAC === "FD") {
          singleReceptionWithProps.STATUS_FAC_FOR = "En factura global";
        } else {
          singleReceptionWithProps.STATUS_FAC_FOR = "Sin timbrar";
        }

        return singleReceptionWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
